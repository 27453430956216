<template>
  <v-app>
    <v-container fluid full-height style="margin-top:160px;">
     

          <v-row  style="margin-top:10px;margin-bottom: 30px;" v-if="scan_button">
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-btn rounded class="primary_button" @click="scan_dialog=true;scanner_error=false"
              ><v-icon left dark>mdi-camera</v-icon> {{$t('btn_open')}}</v-btn
            >
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>


        <v-row  style="margin-top:10px;margin-bottom: 30px;" v-if="send_button">
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-btn rounded class="primary_button" @click="send_task"
              ><v-icon left dark></v-icon> {{$t('btn_send_task')}}</v-btn
            >
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        













        <v-card class="rounded-lg" style="padding:14px;" elevation="10" v-if="tdata['type'] == 'ex_photo_dmx' || tdata['type'] == 'mlevel_sku_photo_dmx'|| tdata['type'] == 'mlevel_sku_photo_dmx_multi'">
          <v-row v-if="tdata['type'] == 'ex_photo_dmx' || tdata['type'] == 'mlevel_sku_photo_dmx'|| tdata['type'] == 'mlevel_sku_photo_dmx_multi'">
          
          <v-col cols="12" align="center">
          
            <p v-html="tdata['description']" style="font-size:18px;width:100%"></p>
            <p style="text-align:left;margin:5px;" v-if="this.selected_level!=null" v-html="this.tdata['levels'][this.selected_level].full_desc"></p>
            <!-- <p v-for="(sku,i) in skus_dmx" :key="i" :class="sku['scanned']?'scanned':'not_scanned'" style="text-align:left;margin-left:30px;font-weight:bold;">{{sku['id']}}. {{sku['name']}}</p> -->
            <v-row v-for="(sku,i) in skus_dmx" :key="i" style="border-bottom:solid 2px grey">
              <v-col cols="6"><span v-html="sku['name']"></span><br><br><span :class="sku['scanned']">{{$t(sku['scanned'])}} ( {{ sku['scanned_arr'].length }}шт.)</span></v-col>
              <v-col cols="6"><img :src="sku['img']" style="max-height: 140px;width:auto;border:solid black 0px;"></v-col>
            </v-row>
            
            
          </v-col>
        </v-row>
        <!-- <v-row min-height>
          <v-col cols="12" align="center">

            <p v-if="tdata['type'] == 'sku_photo'" v-html="tdata['sku_list']"></p>
            <p style="text-align:left;margin:5px;" v-if="this.selected_level!=null" v-html="this.tdata['levels'][this.selected_level].full_desc"></p>
           
            
          </v-col>
        </v-row> -->



        </v-card>
        

        
        

        

    





      <v-dialog v-model="scan_dialog" fullscreen> <!--fullscreen hide-overlay-->
        <v-overlay v-model="scanner_progress_info">
        <!-- <span v-html="scanner_progress_info_text"></span> -->
          <v-dialog
              
             v-model="scanner_progress_info_inner_dialog"
              width="330" style="z-index:55000;"
            >
            <v-card class="rounded-lg" style="" elevation="50">
                <v-card-title style="background-color:orange;color:white;margin-bottom:15px;text-align:center;justify-content: center;padding-left:5px;padding-right:5px;">Повідомлення</v-card-title>
                <v-card-text>
                  <v-row> <v-col v-html="scanner_progress_info_text">
                  
                      </v-col>
                  </v-row>
                  <p v-if="scanned.length>0">Вже відскановано {{scanned.length}} SKU:</p>
          <p v-for="(skus,j) in scanned" :key="j" style="text-align:left;margin-left:30px;font-weight:bold;" v-html="skus['name']+' <br>('+skus['scanned_arr'].length+' шт.)'"></p>
                
                  
                </v-card-text>
                <v-card-actions>
                  
                  <v-btn
                    color="primary"
                    text
                    @click="scanner_progress_info = false"
                  >
                    Закрити
                  </v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
        
        </v-overlay>
        
      <v-card>
        <v-card-title   style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;position: relative;" class="mb-0">
          <!-- header -->
          <v-btn icon  @click.native="scan_dialog = false" style="z-index: 1;position:absolute;top:0;bottom:0;left:15px;margin:auto">
              <v-icon  x-large color="white"> mdi-close</v-icon>
          </v-btn>
          {{$t('h_scan')}}  
        </v-card-title>
       
        <v-card-text class="mt-0 ml-0 mr-0 pt-0 pr-0 pl-0">
          <v-row align="top" justify="center">
          <v-col cols="12" sm="4" md="4" class=""> <!--class="ma-0 pa-0" -->
          <StreamBarcodeReader model="scanner_object" @decode="onDecode" @loaded="onLoaded" @error="onError" style="width:100%;" ><!-- :scanned_code="scan_info_text" -->
           
          
          </StreamBarcodeReader>
          <!-- <button @click="snapshot">snapshot</button>  -->
          <canvas id="canvas" style="display:none"> </canvas>
          
            <img id="photo"  style="display:none"/>
          
          <span v-if="scanner_error">
          Ми не змогли відкрити сканер. Можливо раніше ви заборонили використання камери в браузері або на сторінці додатку.
          Надайте дозвіл на використання камери, скориставшись наступними інструкціями:<br>
          <a href="https://support.google.com/googleplay/answer/9431959?hl=ru&visit_id=638055749556009593-677422070&rd=1" target="_blank">Системні налаштування</a><br>
          <a href="https://support.google.com/chrome/answer/2693767?hl=ru&co=GENIE.Platform%3DAndroid" target="_blank">Налаштування сторінки</a><br>
          
          </span>
         </v-col>
          </v-row>
          <!-- <p>{{scan_info_text}}</p> -->
          <p v-if="scanned.length>0">Вже відскановано {{scanned.length}} SKU:</p>
          <p v-for="(skus,j) in scanned" :key="j" style="text-align:left;margin-left:30px;font-weight:bold;" v-html="skus['name']+' <br>('+skus['scanned_arr'].length+' шт.)'"></p>
          <p style="text-align:left;margin-top:10px;">Залишилось відсканувати: </p>
          <p v-for="(sku,i) in not_scanned" :key="'A'+ i" style="text-align:left;margin-left:30px;font-weight:bold;" v-html="sku['name']+' <br>('+(sku['nec_count']-sku['scanned_arr'].length)+' шт.)'"></p>
             


        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="scan_dialog = false">   {{$t('close')}} </span>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="level_select_dialog" persistent>
      <v-card>
        <v-card-title   style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;">
          <!-- header -->
          Оберіть рівень складності
        </v-card-title>
       
        <v-card-text>
          <v-radio-group v-model="selected_level">
          <v-row fluid justify="center" v-for="(item,index) in tdata['levels']" :key="index"  >
                
                <v-col cols="2" style="">

                  <v-radio                 
                    :key="index"                   
                    :value="index"
                  ></v-radio>
                </v-col>
                <v-col cols="10" v-html="item.short_desc">        
                  
                </v-col>
               <v-col cols="12" ><hr></v-col>
              </v-row>
          </v-radio-group>
        </v-card-text>
        <!-- <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="write_tp = false">   {{$t('close')}} </span>
        </v-card-actions> -->
      </v-card>
    </v-dialog>



    




    </v-container>
  </v-app>
</template>

<style lang="scss" scoped>
$switch-track-dense-height:28px ;
$switch-track-height:28px;
$switch-thumb-height:40px;
</style>

<style scoped>
input[type="file"] {
  display: none;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>


<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
import axios from "axios";
import { API_LOCATION } from "../../config";

// import ass from "../components/ass.vue";
// import ass_pg from "../components/ass_pg.vue";
export default {
  beforeRouteLeave (to, from, next) { 
    if(this.url)
    {
          const answer = window.confirm('Ви не надіслали фото! Дійсно бажаєте вийти з завдання?')
        if (answer) {
          next()
        } else {
          next(false)
        }
          console.log(to) 
          console.log(from) 
          console.log(next) 
    }
    else
    {
      next()
    }
    
    }
    
    ,
  components: {
    StreamBarcodeReader
    // eslint-disable-next-line vue/no-unused-components
    // ass,ass_pg
  },
  data() {
    return {
      scanner_progress_info_inner_dialog:true,
      scanner_progress_info:false,
      scanner_error:false,
      scan_button:true,
      send_button:false,
      rand_code:'',
      url: null,
      file: "",
      task: false,
      task_id: null,
      descr: "",
      header: "",
      tdata: [],
      da:false,
      gl:false,
      fm:false,
      foto_btn_display:false,
      rules_disp:false,
      rules_extra_disp:false,
      time:5,
      timer: null,
      g:{long:'',lat:'',coord_state:''},
      scan_dialog:false,
       req_tp_tp:'',
        apiurl:API_LOCATION,
        switch1:true,
        level_select_dialog:false,
        selected_level:null,
      text_data:'',
      scan_info_text:'',
      skus_dmx:null,
      full_skus_dmx:null,     
      scanner_object:null,
      unknown_rsku_img_data:null,
      unknown_rsku:'',
      iteration_scanned:[]
    }
  },
  mounted() {

  },
  methods: {
    snapshot(result){
      let canvas = document.getElementById('canvas');
      let photo = document.getElementById('photo');
      let video = document.getElementById('video');
      let dimensions=video.getBoundingClientRect();
      canvas.width  = dimensions.width;
      canvas.height = dimensions.height;
      const context = canvas.getContext("2d");
      
      context.drawImage(video,0,0,dimensions.width,dimensions.height);

      const data = canvas.toDataURL("image/png");
      photo.setAttribute("src", data);




      axios
      .post(
        API_LOCATION,
        {
          
          action: "unknown_rsku",
          task:this.task_id,
          dmx_code:result,
          img_data:data
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {

              
              console.log('img_sent:'+resp);
        
      });




    },
    async cacheAdd(code){

      return await  axios
      .post(
        API_LOCATION,
        {
          
          action: "dmx_cache_add",
          task_id:this.task_id,
          dmx_code:code
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {

              
              console.log(code+' saved:'+resp.data);
              
              return  ''      
              
      });
      

    },

    loadCache(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "dmx_cache_get",
          task_id:this.task_id
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {

              
              //console.log('cache:'+resp.data);
              resp.data.forEach(el =>{

                console.log(el);
                this.onDecode(el,true);
              })
      });
    },

    onLoaded() {
      console.log("load");
    },
    onError(err) {
      console.log(err);
      this.scanner_error=true;
    },
    onDecode (result,silent=false) { 
      
      var data={
        2203701:"Davidoff Classic Slims"
      };
      
      var rsku='';
      // var state="";
     //alert(result); 
     if(this.iteration_scanned.includes(result)){
      return;
     }
      if(result.length>15){//не штрихкод
        
        /*if(strlen($rsku)>32){
			$rsku=substr($rsku,-7,7);
		}
		else{
			$rsku=substr($rsku,4,7);
		} */

    if(result.length>32){//разные форматы датаматрикс кода
       rsku=result.substring(result.length-7);
    }
    else{
      rsku=result.substring(4,11);
    }

        console.log(result,rsku,data); //alert(data[rsku]);//alert(result);
        //alert(rsku);
        this.scan_info_text=result;

        let in_task=false;
        let in_global_db=false;

        this.skus_dmx.forEach(element=>{

          element['rskus'].forEach(rsku_current=>{ 
            if(rsku_current==rsku){ 
              //element['scanned']='scanned'; 
              element.full_code=result;  
              if(!element['scanned_arr'].includes(result)){!element['scanned_arr'].push(result)}
              element.full_code=result; 
              if(!silent){
               // alert('check_start');
               this.cacheAdd(result).then(function(resp){
                //alert('check_result_ready');
                //alert(resp);
                this.scanner_progress_info=true; 
                this.scanner_progress_info_text=resp+'<br>Щойно відскановано: <br><b>'+element['name']+'</b><br><span style="color:green">Бере участь у завданні</span>';
                
               }.bind(this));
              }
              in_task=true;
            } 
            })
          
        });

        if(!in_task){
           this.full_skus_dmx.forEach(element=>{

          element['rskus'].forEach(rsku_current=>{ 
            if(rsku_current==rsku){ 
             // element['scanned']='scanned';               
              element.full_code=result;  
              if(!silent){
                this.scanner_progress_info=true; 
                this.scanner_progress_info_text='Щойно відскановано: <br><b>'+element['name']+'</b><br><span style="color:red">Не бере участь у завданні</span>';
                
              }
              in_global_db=true;
            } 
            })
          
        });

        }

        if(!in_global_db&&!in_task&&!silent){
              this.scanner_progress_info=true; 
              this.scanner_progress_info_text='<span style="color:red">Щойно відскановано невідомий код<br>Зверніться, будь ласка, до технічної підтримки.</span>';
              this.iteration_scanned.push(result);
              this.snapshot(result);
        }


        
        if(this.not_scanned.length==0||this.scanned.length==this.task_nec_sku){ this.scan_dialog=false; this.scan_button=false; this.send_button=true; this.send_task();}

        // if(this.scanned.includes(result))
        // {
        //   state="Раніше відскановано";
        // }
        // else{
        //   state="Новий блок"; 
        //   this.scanned.push(result);
        // }
        // this.text_data=data[rsku]+":  "+state+"<br>RSKU:"+rsku+"<br>FULL_CODE:"+result+"<br>"+this.text_data;
        
      
        }
      
      
      
      
      } ,

    send_task: function(){

      
      
      axios
        .post(API_LOCATION,
        { data: "component_data", action: "save_dmx", task: this.task_id, skus:this.skus_dmx, selected_level:this.selected_level },
          { headers: { "content-type": "application/x-www-form-urlencoded" } }
        )
        .then((resp) => {
          console.log("SUCCESS!!");
          //alert(JSON.stringify(resp));
          if (resp.data == "OK") {

            
            this.$router.push({ name: "Tasks" });
            // this.$router.push('/')
          }
          else if(resp.data.error == "again"){

            this.$emit('pushe', {header:'Помилка',text:'Один блок може прийняти участь у завданні лише раз. Відскануйте повторно.'});
            this.scan_button=true;
            this.send_button=false;
            resp.data.failed.forEach(wrong=>{

              this.skus_dmx.forEach(element=>{
                //alert(element.full_code+'=='+wrong)
                if(element.full_code==wrong){ element['scanned']='already_scanned'; element.full_code=''; }
                    // element['rskus'].forEach(rsku_current=>{ if(rsku_current==wrong){ element['scanned']='already_scanned'; element.full_code=''; } })

                    });

            });

          }
          else{
            return
            //this.$router.push({ name: "Tasks" });
          }
        });

    },  

    
    get_user_rand_code: function(){
        axios
      .post(
        API_LOCATION,
        {
          
          action: "get_user_rand_code",
          task:this.task_id,
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {

              this.rand_code=resp.data;
              console.log('code:'+resp.data);
        
      });
    },

    startTimer() {
      this.timer = setInterval(() => {
        this.time--
        console.log(this.time);
      }, 1000)
    },
    stopTimer() {
      this.foto_btn_display=true
      clearTimeout(this.timer)
    },
    clickHandler() {
      this.$refs.file.click();
    },
    place_image: function () {
      let formData = new FormData();
      formData.append("action", "img_upload");
      formData.append("task", this.task_id);
      formData.append("rand_code", this.rand_code);
      formData.append("file", this.file);
      formData.append("longtitude", this.g.long);
      formData.append("latitude", this.g.lat);
      if(this.tdata['type'] == 'mlevel_lay_photo'||this.tdata['type'] == 'mlevel_sku_photo')
      {
        formData.append("choosen_level", this.selected_level);
      }

      console.log(this.file);
      axios
        .post(API_LOCATION, formData, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((resp) => {
          console.log("SUCCESS!!");
          if (resp.data == "OK") {
            this.url=null;
            this.$router.push({ name: "Tasks" });
            // this.$router.push('/')
          }
          //alert(resp.data);
          console.log(resp.data);
        });
    },
    capture() {
        if(this.switch1==true){
          this.canvas = this.$refs.canvas;
          // eslint-disable-next-line no-unused-vars
          var context = this.canvas
            .getContext("2d")
            .drawImage(this.video, 0, 0, 640, 480);
          // eslint-disable-next-line no-undef
          this.captures.push(canvas.toDataURL("image/png"));
        }
    },

   
    onFileChange(e) {
      const file = e.target.files[0];
      this.file = this.$refs.file.files[0];
      console.log(e.target.files[0]);
        if(this.switch1==true){
          this.url = URL.createObjectURL(file);
        }
        else{
            this.url=true;
        }
      // if((e.target.files[0].lastModified+100000)<Date.now())
      // {
      //   //alert('MODIFIED:'+e.target.files[0].lastModified+"\nDate:"+Date.now());
      //   alert('Не з галереї 😁');
      //   this.url=null
      // }
    },
    navOnSuccess(position) {
                 console.log('Latitude: '          + position.coords.latitude          + '\n' +
                        'Longitude: '         + position.coords.longitude         + '\n' +
                        'Altitude: '          + position.coords.altitude          + '\n' +
                        'Accuracy: '          + position.coords.accuracy          + '\n' +
                        'Altitude Accuracy: ' + position.coords.altitudeAccuracy  + '\n' +
                        'Heading: '           + position.coords.heading           + '\n' +
                        'Speed: '             + position.coords.speed             + '\n' +
                        'Timestamp: '         + position.timestamp                + '\n');
                        
                        
                        this.g.long = position.coords.longitude
                        this.g.lat = position.coords.latitude
                        this.g.coord_state='success'
                        console.log('long: '+this.g.long+': lat:'+this.g.lat)
                        //alert('long: '+this.g.long+': lat:'+this.g.lat)
                        console.log(this.g);

              },

              navOnError(error) {
                  console.log('NAV_FAIL: code: '    + error.code    + '\n' +
                        'message: ' + error.message + '\n')
              }
  },
  computed: {
    not_scanned: function () {
      //return this.skus_dmx.filter((i) => i.scanned == 'not_scanned'||i.scanned == 'already_scanned');
      return this.skus_dmx.filter((i) => i.scanned_arr.length < i.nec_count);
    },
    scanned: function () {
      return this.skus_dmx.filter((i) => i.scanned_arr.length >0);
    },
    title() {
        return this.$t('title')
      }
  },
  watch:{
      title: function(){
        this.$store.dispatch("page_title_set", this.title);
      },
      time(time) {
      if (time === 0) {
        this.stopTimer()
      }
      },
      
      selected_level: function(){
        if(this.selected_level!=null){
          this.level_select_dialog=false;
          console.log(this.tdata['levels'][this.selected_level].full_desc)

          if(this.tdata['type'] == 'mlevel_sku_photo_dmx')
          {
            this.task_nec_sku=this.tdata['levels'][this.selected_level].nec_sku;
            console.log("NEC_SKU:"+this.task_nec_sku);

            this.loadCache();


          }
          if(this.tdata['type'] == 'mlevel_sku_photo_dmx_multi')
          {
            Object.entries(this.tdata['levels'][this.selected_level]['data']).forEach(element => {
              console.log("ELEMENT  "+element);
              console.log('list_num: '+element[0]+"\r\nname: "+element[1]['name']);
              let nec_count=1;
              if(typeof(element[1]['nec_count']) != "undefined" && element[1]['nec_count'] !== null) {
                      nec_count=element[1]['nec_count'];
                    }

              this.skus_dmx.push({'id':element[0],'name':element[1]['name'],'rskus':element[1]['rskus'],'scanned':'not_scanned','full_code':'','img':element[1]['img'],scanned_arr:[],'nec_count':nec_count});



            });  

            this.loadCache();


          }
        }
      }
  },
  created() {



      console.log(this.g);

      
              if (navigator.geolocation)
              {
              navigator.geolocation.getCurrentPosition(this.navOnSuccess, this.navOnError,{enableHighAccuracy:false,maximumAge:Infinity, timeout:60000})
               console.log('start_nav_detect')
              }
              else
              {
                console.log('geolocation_not_supported')
              }














    console.log(this.$route);
    
    this.$store.dispatch("page_title_set", this.title);
    this.$store.dispatch("profileset");



        console.log(this.$route.params.routeparam);
    if (this.$route.params.routeparam) {
      this.task_id = this.$route.params.routeparam;
      this.task = true;
      axios
        .post(
          API_LOCATION,
          { data: "component_data", action: "load_task", id: this.task_id },
          { headers: { "content-type": "application/x-www-form-urlencoded" } }
        )
        .then((resp) => {
          this.tdata = JSON.parse(resp.data.data_json);
          this.skus_dmx=[];
          this.full_skus_dmx=[];
          if(this.tdata['type'] == 'ex_photo_dmx'||this.tdata['type'] == 'mlevel_sku_photo_dmx')
          {
            Object.entries(this.tdata['data']).forEach(element => {
              console.log('list_num: '+element[0]+"\r\nname: "+element[1]['name']);
              let nec_count=1;
              if(typeof(element[1]['nec_count']) != "undefined" && element[1]['nec_count'] !== null) {
                      nec_count=element[1]['nec_count'];
                    }

              this.skus_dmx.push({'id':element[0],'name':element[1]['name'],'rskus':element[1]['rskus'],'scanned':'not_scanned','full_code':'','img':element[1]['img'],scanned_arr:[],'nec_count':nec_count});



            });  

            Object.entries(this.tdata['full_db']).forEach(element => {
              console.log('full_list_num: '+element[0]+"\r\nname: "+element[1]['name']);
              
              this.full_skus_dmx.push({'id':element[0],'name':element[1]['name'],'rskus':element[1]['rskus'],'scanned':'not_scanned','full_code':''});



            });  

          }

          if(this.tdata['type'] == 'ex_photo_dmx')
          {
            this.task_nec_sku=this.tdata['nec_sku'];
            console.log("NEC_SKU:"+this.task_nec_sku);
          }


          console.log(this.skus_dmx);
          console.log(this.full_skus_dmx);




          if(this.tdata['type'] == 'mlevel_lay_photo'||this.tdata['type'] == 'mlevel_sku_photo'||this.tdata['type'] == 'mlevel_sku_photo_dmx'||this.tdata['type'] == 'mlevel_sku_photo_dmx_multi')
          {
            this.level_select_dialog=true;
          }

          if(this.tdata['type'] == 'lay_photo')
          {
           
            this.foto_btn_display=true;
            //this.startTimer();
          }
          else
          {
             this.get_user_rand_code();
            this.foto_btn_display=true;
          }

           if(this.tdata['type'] == 'ex_photo_timer')
          {
          //  let start=new Date(this.tdata['start_active'].replace(/-/g,'/').replace('T',' ').replace(/\..*|\+.*/,"")).getTime();
          //  let end=new Date(this.tdata['end_active'].replace(/-/g,'/').replace('T',' ').replace(/\..*|\+.*/,"")).getTime();


                  
          // var arr = this.tdata['start_active'].split(/[- :]/);
          // let start = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]).getTime();
          // console.log(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
               
          //  arr = this.tdata['end_active'].split(/[- :]/);
          // let end = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]).getTime();

            let start=new Date(this.tdata['start_active'].replace(" ", "T")).getTime();
           let end=new Date(this.tdata['end_active'].replace(" ", "T")).getTime();




           let now=new Date().getTime();
            console.log(start,now,end);
            /*
            alert(this.tdata['start_active']);
            alert(start);
            alert(now);
            alert(end);*/

            if(start<now&&end>now)         
             {
                this.foto_btn_display=true;
              }
              else{
                this.foto_btn_display=false;
              }

          }

          // this.descr = this.tdata.description_ru;
          // this.header = this.tdata.header_ru;

          const ex = resp.data.id_ex
          const moderate=resp.data.moderate
          const correct=resp.data.correct
          this.$store.dispatch("page_title_set", this.tdata['header']);
          if(ex!=null)
          {
            this.task = false;
            this.$router.push({ name: "Tasks" });
            if(moderate==1)
            {
              this.$emit('pushe', {header:'Повідомлення',text:'Завдання знаходиться на модерації.'});
            }
            else
            {
              
              var task_state=correct==1?'Успішно':'Помилка';
              this.$emit('pushe', {header:'Повідомлення',text:'Завдання вже виконано.<br>Статус: <b>'+task_state+'</b>'});
            }
          }
          console.log(this.tdata);
        });
    } else {
      this.task = false;
       this.$router.push({ name: "Main" });
    }
  },
};
</script>
<i18n>
{
  
  "ua":{
    "h_contact":"Надіслати повідомлення торговому представнику",
    "h_scan":"Сканер",
      "title":"Зробіть фото",
      "btn_take":"Зробити фото",
      "btn_retake":"Зробити нове фото",
      "btn_send":"Надіслати фото",
      "btn_send_task":"Надіслати завдання",
      "btn_open":"Відкрити сканер",
      "close":"Закрити",
      "rules_txt":"Увага! Продукція має бути розміщена згідно правил викладки",
      "rules_extra_txt":"Увага! Блок має бути запакований, а код нанесений відповідно прикладу.",
      "sku_rules_txt":"ВАШ УНІКАЛЬНИЙ КОД  <br><font style='color:#ed8000;font-size:40px;border: solid 3px black;padding:5px;'><b>{count}</b></font>",
      "rules_lnk":"Переглянути викладки",
      "rules_extra_lnk":"Переглянути приклад",
      "scanned":"відскановано",
      "not_scanned":"не відскановано",
      "already_scanned":"помилка - блок приймав участь"
      

  }
}
</i18n>

<style>



.scanned{
  font-size: 13px;
  font-style: italic;
  color: green;
}
.not_scanned{
  font-size: 13px;
  font-style: italic;
  color: gray;
}

.already_scanned{
  font-size: 13px;
  font-style: italic;
  color: #ff9900;
}

.tp_write { 
  font-size: 26px;
    font-weight: bold;
    color: #ff9900;
  /*
  animation-name: myanimation;
  animation-duration: 5s;
  animation-iteration-count: infinite;*/
  
}


.tp_write_small { 
    color: #ff9900;
  /* animation-name: myanimation;
  animation-duration: 3s;
  animation-iteration-count: infinite;*/
  
}


@keyframes myanimation {
  0%   {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
  }
  50%  {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2)
  }
  100%  {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
  }
}
</style>